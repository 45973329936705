import type { Instance } from "@gadgetinc/mobx-quick-tree";
import { createContext, useContext } from "react";
import type { ClientApplicationRoot } from "../../../client-state-trees/workspace/ClientApplicationRoot";
import type { ClientEnvironmentRoot } from "../../../client-state-trees/workspace/ClientEnvironmentRoot";

export const ApplicationRootContext = createContext<ClientApplicationRoot>(null as any);
export const useCurrentApplication = () => useContext(ApplicationRootContext);

export const CurrentEnvironmentRootContext = createContext<Instance<typeof ClientEnvironmentRoot>>(null as any);
export const useCurrentEnvironment = () => useContext(CurrentEnvironmentRootContext);
export const useCurrentEnvironmentSettings = () => useCurrentEnvironment().settings.getOrSuspend();

export const ProductionEnvironmentRootContext = createContext<Instance<typeof ClientEnvironmentRoot>>(null as any);
export const useProductionEnvironment = () => useContext(ProductionEnvironmentRootContext);
